.about {
  max-width: 1200px;
  margin: auto;
  padding: 80px 20px;
  animation: fadeIn 1s ease-in-out;
  background: #ffffff;
  border-radius: 12px;
  /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */
  text-align: center;
  color: #444;
}

.about h1 {
  font-size: 2.5rem;
  color: #222;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
}

.about h1::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: #007acc;
  margin: 10px auto 0;
  border-radius: 2px;
}

.about p {
  margin-top: 20px;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  text-align: justify;
  text-justify: inter-word;
}


@media (max-width: 768px) {
  .about {
    margin-top: 70px;
      padding: 60px 15px;
  }

  .about h1 {
      font-size: 2rem;
  }

  .about p {
      font-size: 1rem;
      line-height: 1.5;
  }
}

@media (max-width: 480px) {
  .about {
      padding: 40px 10px;
  }

  .about h1 {
      font-size: 1.8rem;
  }

  .about h1::after {
      width: 60px;
  }

  .about p {
      font-size: 0.95rem;
      line-height: 1.4;
      text-align: left;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

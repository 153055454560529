
.mv-container {
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
  
  @media (max-width: 768px) {
    .mv-container {
      grid-template-columns: 1fr;
    }
  }
  
 
  .mv-section {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    color: white;
  }
  
  .mv-mission {
    background-color: #FFCC00;
  }
  
  .mv-vision {
    background-color: #333333;
  }
  
 
  .mv-heading {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .ms-heading{
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
 
  .mv-text {
    text-align: justify;
    text-justify: inter-word;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  .ms-text {
    text-align: justify;
    text-justify: inter-word;
    color: black;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  
  .mv-list {
    list-style: none;
    padding: 0;
  }
  
  
  .mv-list-item {
    margin:5px;
    gap: 5px;
    text-align: justify;
    text-justify: inter-word;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  
  .mv-list-item::before {
    content: "■";
    margin-right: 0.5rem;
  }
  .ms-list {
    list-style: none;
    padding: 0;
  }
  
  
  .ms-list-item {
    margin:5px;
    gap: 5px;
    text-align: justify;
    text-justify: inter-word;
    color: black;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  
  .ms-list-item::before {
    content: "■";
    margin-right: 0.5rem;
  }
  

  .ms-image-container {
    background-image: url('/src/assets/images/mission.jpg'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 350px;
  }

  .mv-image-container {
    background-image: url('/src/assets/images/vision.jpg'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 350px;
  }
  
.mollin-footer {
  background-color: #20232a;
  color: #ffffff;
  padding: 40px 20px;
  font-family: "Roboto", sans-serif;
  position: relative;
  overflow: hidden;
}

.mollin-footer-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: auto;
}

.mollin-footer-section {
  text-align: left;
}

.mollin-footer-section h2,
.mollin-footer-section h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #f1f1f1;
}

.mollin-footer-section p {
  line-height: 1.6;
  font-size: 14px;
  color: #d1d1d1;
}

.mollin-footer-section a {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  display: block;
  margin: 5px 0;
  transition: color 0.3s ease;
}

.mollin-footer-section a:hover {
  color: #f1c40f;
}

.kp-logo-image {
  height: 50px;
  margin-right: 10px;
}

.mollin-footer-bottom {
  margin-top: 40px;
  text-align: center;
  color: #a1a1a1;
  font-size: 14px;
  padding: 10px 20px;
  border-top: 1px solid #444;
}

.mollin-footer-bottom p {
  margin: 0;
}

@media (max-width: 768px) {
  .mollin-footer-container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .mollin-footer-section {
    margin-bottom: 20px;
  }

  .mollin-footer-section p,
  .mollin-footer-section a {
    font-size: 13px;
  }

  .kp-logo-image {
    margin: auto;
    display: block;
  }
}

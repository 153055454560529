.team-section {
  text-align: center;
  padding: 40px 150px;
}

@media (max-width: 1024px) {
  .team-section {
    padding: 40px 80px;
  }
}

@media (max-width: 768px) {
  .team-section {
    padding: 20px 40px;
  }
}

@media (max-width: 480px) {
  .team-section {
    padding: 20px 20px;
  }
}

.team-title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #333;
}

@media (max-width: 480px) {
  .team-title {
    font-size: 1.5rem;
  }
}

.team-description {
  color: #666;
  margin-bottom: 2rem;
}

@media (max-width: 480px) {
  .team-description {
    font-size: 0.875rem;
  }
}

.team-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  align-items: center;
}

.team-member {
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.team-member:hover {
  transform: translateY(-5px);
}

.team-image {
  width: 50%;
  height: auto;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .team-image {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .team-image {
    width: 70%;
  }
}

.member-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1e1e1e;
  margin-top: 1rem;
}

@media (max-width: 480px) {
  .member-name {
    font-size: 1rem;
  }
}

.member-role {
  font-size: 1rem;
  color: #555;
}

@media (max-width: 480px) {
  .member-role {
    font-size: 0.875rem;
  }
}

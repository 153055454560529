.why-choose-us-section {
  padding: 80px 100px;
  background-color: #f8f9fa;
}

.why-choose-us-container {
  margin: 0 auto;
  /* display: grid; */
  /* grid-template-columns: 1fr; */
  align-items: center;
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.why-choose-us-left h2 {
  font-size: 1.75rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.reasons-list {
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.reason-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;
}

.icon-circle {
  background-color: #fbbd08;
  color: white;
  font-size: 1.5rem;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.reason-item h3 {
  font-size: 1.15rem;
  color: #333;
}

.reason-item p {
  width: 100%;
  font-size: 0.95rem;
  color: #666;
  margin: 0;
}

.why-choose-us-right {
  text-align: center;
  margin-top: 20px;
}

.curved-image {
  width: 100%;
  max-width: 300px;
  border-radius: 50px;
  object-fit: cover;
}
.product-display-container {
  width: 100%;
  /* padding: 2rem; */
  background-color: #f7f9fc;
  border-radius: 10px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  /* max-width: 1200px; */
  /* margin: auto; */
}

.product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.product-header h2 {
  font-size: 2rem;
  color: #333;
}

.view-all-button {
  background-color: #000000;
  color: #fff;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-all-button:hover {
  background-color: #ffffff;
  color: #000000;
}

.product-slider {
  padding: 0 1rem;
}

.product-card {
  gap: 5px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.product-image {
  width: 50%;
  height: auto;
  object-fit: contain;
  /* max-width: 180px;
  border-radius: 8px;
  margin-bottom: 1rem; */
}

.product-details {
  margin-top: 0.8rem;
}

.product-title {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.product-price {
  font-size: 1.1rem;
  font-weight: bold;
  color: #0d223a;
}

.slick-arrow {
  background-color: #080a0b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: background-color 0.3s ease;
}

.slick-arrow:hover {
  background-color: #000000;
}
@media screen and (max-width: 769px) {
  .why-choose-us-section {
    padding: 60px 30px;
  }
  .why-choose-us-right {
    text-align: center;
    margin-top: 50px;
  }
  .product-display-container {
    margin-top: 20px;
    width: 100%;
    background-color: #f7f9fc;
    border-radius: 10px;
  }
}

@media screen and (min-width: 769px) {
  .why-choose-us-section {
    padding: 60px 100px;
  }

  .why-choose-us-container {
    grid-template-columns: 1fr 1fr;
    max-width: 1200px;
  }

  .why-choose-us-left h2 {
    font-size: 2rem;
    text-align: left;
  }

  .reasons-list {
    gap: 25px;
  }

  .reason-item {
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
    gap: 20px;
  }

  .icon-circle {
    width: 60px;
    height: 60px;
    font-size: 2rem;
  }

  .reason-item p {
    width: 300px;
    font-size: 1rem;
  }

  .curved-image {
    max-width: 450px;
    border-radius: 150px 0px 150px 150px;
  }
}
/* hidsadansckackansca */

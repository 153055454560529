.product-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  padding: 20px;
}
.big-product-container {
  margin: 0px 150px;
}
.Product-name {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.see-all-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.see-all-button:hover {
  background-color: #0056b3;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  text-align: center;
  padding: 10px;
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card:hover {
  transform: scale(1.05);
}

/* .product-image {
  
  width: 100%;
  height: 250px;
  object-fit: contain;
} */

.product-info {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.product-title {
  font-size: 1em;
  color: #333;
  margin: 0;
}

.product-price {
  font-size: 1.2em;
  color: #007bff;
  margin: 5px 0;
}

.product-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.add-to-cart-button {
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 12px;
  cursor: pointer;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: white;
  color: #333;
  font-size: 1em;
  transition: background-color 0.3s, color 0.3s;
}

.pagination button.active {
  background-color: #333;
  color: white;
  font-weight: bold;
}

.pagination button:hover:not(.active) {
  background-color: #e0e0e0;
}
.image-container {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.product-image {
  width: 100%;
  /* height: 100%; */
  height: 250px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.image-container:hover .product-image {
  transform: scale(1.1); /* Slight zoom effect */
}

.buy-now-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .buy-now-overlay {
  opacity: 1;
}

@media (max-width: 600px) {
  .buy-now-overlay {
    font-size: 0.85em;
    padding: 8px 15px;
  }
  .pagination {
    flex-direction: row;
  }

  .pagination button {
    margin: 5px 0;
    padding: 8px 10px;
    font-size: 0.9em;
  }
}

@media (min-width: 601px) {
  .pagination button {
    padding: 10px 15px;
    font-size: 1em;
  }
}

@media (max-width: 1024px) {
  .product-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .pagination button {
    font-size: 0.95em;
  }
  .product-list {
    grid-template-columns: repeat(2, 1fr);
  }
  .big-product-container {
    margin: 0;
    padding: 0 10px;
  }
  .product-card {
    padding: 8px;
  }

  .product-title {
    font-size: 0.9em;
  }

  .product-price {
    font-size: 1.1em;
  }

  .add-to-cart-button {
    font-size: 1.3em;
  }

  .see-all-button {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .product-list {
    grid-template-columns: 1fr;
  }
  .big-product-container {
    margin: 0;
    padding: 0 5px;
  }
  .product-card {
    padding: 6px;
  }

  .product-title {
    font-size: 0.85em;
  }

  .product-price {
    font-size: 1em;
  }

  .add-to-cart-button {
    font-size: 1.2em;
  }

  .see-all-button {
    width: 100%;
    font-size: 0.85em;
    padding: 8px 0;
  }
}
/* dummy buttons */
/* Container styles */
.custom-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  padding: 0.5rem;
  gap: 0; /* No gap to make buttons join */
}

/* Shared button styles */
.custom-button {
  flex: 1; /* Ensures buttons are of equal size */
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  border: none;
  border-radius: 0;
  transition: all 0.3s ease;
  text-align: center;
}

/* Add to Cart button styles */
.custom-add-to-cart-button {
  background-color: #ffffff;
  color: #333;
  border: 2px solid #080808;
  border-right: 1px solid #131415; /* Shared border */
  border-radius: 8px 0 0 8px; /* Rounded left corners */
}

.custom-add-to-cart-button:hover {
  background-color: #030303;
  color: #ffffff;
}

/* Buy Now button styles */
.custom-buy-now-button {
  background-color: #0b0a0a;
  color: #ffffff;
  border: 2px solid #010101;
  border-left: 1px solid #080808; /* Shared border */
  border-radius: 0 8px 8px 0; /* Rounded right corners */
}

.custom-buy-now-button:hover {
  background-color: #fdfdfd;
  color: #000000;
}

/* Button hover scale effect */
.custom-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .custom-buttons-container {
    flex-direction: row;
  }
  .custom-button {
    font-size: 0.9rem;
    padding: 0.6rem 0.8rem;
  }
}

/* Main container */
.eliteContact_contact-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Contact form styling */
.eliteContact_contact-form {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  margin: 20px;
}

.eliteContact_contact-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.eliteContact_contact-form form {
  display: flex;
  flex-direction: column;
}

.eliteContact_contact-form input,
.eliteContact_contact-form textarea {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.eliteContact_contact-form button {
  padding: 12px 20px;
  background-color: #0b0b0b;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.eliteContact_contact-form button:hover {
  color: #0b0b0b;
  background-color: #fefefe;
}

/* Contact map styling */
.eliteContact_contact-map {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  height: 400px;
  margin: 20px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Contact info section */
.eliteContact_contact-infos {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.eliteContact_info-item {
  flex: 1;
  min-width: 250px;
  max-width: 350px;
  text-align: center;
  margin: 10px;
}

.eliteContact_info-item i {
  font-size: 24px;
  color: #008080;
  margin-bottom: 10px;
}

.eliteContact_info-item p {
  font-size: 16px;
  color: #555;
}

/* Responsive design */
@media (max-width: 768px) {
  .eliteContact_contact-page {
    flex-direction: column;
    align-items: center;
  }

  .eliteContact_contact-form,
  .eliteContact_contact-map,
  .eliteContact_info-item {
    width: 100%;
    max-width: none;
  }

  .eliteContact_contact-infos {
    flex-direction: column;
  }
}

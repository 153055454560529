.chairman-message-section {
  padding: 40px 20px;
  background-color: #f8f9fa;
}

.chairman-message-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}
.chairman-message-container.scrolled {
  opacity: 1;
  transform: translateY(0);
}
.chairman-image img {
  width: 150px;
  height: auto;
  border-radius: 10px;
}

.chairman-content {
  text-align: center;
  padding-left: 0;
}

.chairman-content h2 {
  font-size: 1.75rem;
  color: #333;
  margin-bottom: 15px;
}

.chairman-quote {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  max-width: 100%;
  margin-bottom: 20px;
}

.chairman-signature {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.signature-image {
  max-width: 100px;
  height: auto;
}

.chairman-signature h3 {
  font-size: 1.15rem;
  color: #333;
}

.chairman-signature p {
  color: #666;
}

@media screen and (min-width: 769px) {
  .chairman-message-section {
    padding: 40px 150px;
  }

  .chairman-message-container {
    flex-direction: row;
    align-items: center;
    text-align: left;
    max-width: 1200px;
    margin: 20px auto;
  }

  .chairman-image img {
    width: 200px;
  }

  .chairman-content {
    text-align: left;
    padding-left: 20px;
  }

  .chairman-content h2 {
    font-size: 2rem;
  }

  .chairman-signature {
    justify-content: flex-start;
  }

  .signature-image {
    max-width: 120px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: none;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #ffffff;
  
}

.App {
  text-align: center;
  overflow-x: clip;
}

button {
  cursor: pointer;
}


@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

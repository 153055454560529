.kp-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: padding 0.3s ease, background-color 0.3s ease;
}

.kp-cart-link {
  position: relative;
  display: flex;
  align-items: center;
}

.kp-cart-badge {
  position: absolute;
  top: -8px;
  right: -10px;
  background-color: #ffcc00;
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  box-sizing: border-box;
}

.kp-cart-icon {
  font-size: 1.5rem;
  color: #333;
}

@media (min-width: 1246px) {
  .kp-navbar {
    margin: 0 150px;
  }
}

.kp-navbar-shrink {
  padding: 5px 20px;
  background-color: rgba(255, 255, 255, 0.9);
}

.kp-navbar-logo {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.kp-navbar-logo a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.kp-navbar-logo:hover {
  color: #ffcc00;
}

.kp-logo-image {
  height: 40px;
  margin-right: 10px;
  transition: height 0.3s ease;
}

.kp-logo-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000000;
}

.kp-logo-text:hover {
  color: #ffcc00;
}

.kp-navbar-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.kp-navbar-links li {
  margin: 0 15px;
}

.kp-navbar-links a {
  text-decoration: none;
  color: #333;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.kp-navbar-links a:hover {
  color: #ffcc00;
}

.kp-navbar-icons {
  display: flex;
  align-items: center;
}

.kp-menu-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.kp-navbar-links-mobile {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 0;
  display: none;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.kp-navbar-links-mobile li {
  margin: 10px 0;
}

.kp-navbar-links-mobile a {
  font-size: 1.2rem;
}

/* dropdown */

/* Dropdown Styling */
.kp-dropdown {
  position: relative;
}

.kp-dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border-radius: 8px; /* Rounded corners */
  z-index: 1000;
  min-width: 220px; /* Slightly wider dropdown */
  padding: 10px 0;
  list-style: none;
  overflow: hidden;
}

/* .kp-dropdown-menu li {
  padding: 10px 20px; 
} */

.kp-dropdown-menu a {
  text-decoration: none;
  color: #333;
  display: block;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 4px; /* Rounded effect for hover */
}

.kp-dropdown-menu a:hover {
  background-color: #ffcc00; /* Highlighted background on hover */
  color: #fff; /* White text on hover */
}

.kp-dropdown:hover .kp-dropdown-menu {
  display: block; /* Show dropdown on hover */
  animation: dropdown-slide 0.3s ease; /* Slide-down animation */
}

.kp-dropdown.kp-dropdown-open .kp-dropdown-menu {
  display: block;
}
.kp-dropdown-menu li a {
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  padding: 10px 15px;
  font-size: 1rem;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.kp-dropdown-menu li a:hover {
  /* background-color: #ffcc00; */
  color: #fff;
}

.kp-dropdown-icon {
  font-size: 0.9rem;
  color: #ffcc00;
  margin-right: 10px;
  transition: color 0.3s ease;
}

.kp-dropdown-menu li a:hover .kp-dropdown-icon {
  color: #fff;
}

/* Slide-down animation */
@keyframes dropdown-slide {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile-friendly dropdown styling */
@media (max-width: 768px) {
  .kp-dropdown-menu {
    max-height: 250px;
    overflow-y: auto; /* Scrollable for long lists */
    width: 100%; /* Full width on mobile */
    padding: 0;
    margin: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for mobile */
  }

  .kp-dropdown-menu li {
    margin: 0;
    padding: 10px 15px;
    border-bottom: 1px solid #f0f0f0; /* Separator for each item */
  }

  .kp-dropdown-menu li:last-child {
    border-bottom: none; /* Remove border for last item */
  }

  .kp-dropdown-menu li a {
    font-size: 1rem;
    color: #333;
  }

  .kp-dropdown-menu li a:hover {
    background-color: #ffcc00;
    color: #fff;
  }
}

/* ending of dropdown */
@media (max-width: 768px) {
  .kp-navbar {
    padding: 10px;
  }

  .kp-navbar-links {
    display: none;
  }

  .kp-menu-icon {
    display: block;
  }

  .kp-navbar-links-mobile {
    display: none;
  }

  .kp-navbar-links-mobile.kp-active {
    display: flex;
  }
}

.enquiry-form-container {
  padding: 70px;
  max-width: 600px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.enquiry-form-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.enquiry-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .enquiry-form-container {
    padding: 15px;
  }

  .enquiry-form-container h2 {
    font-size: 18px;
  }
}


.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 100px 20px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  transition: background-image 1s ease-in-out;
}


.hero-section {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; 
}

.hero-content {
  color: rgb(0, 0, 0);
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: rgb(0, 0, 0); 
  animation: fadeInText 1.2s ease-in-out;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 40px;
  animation: fadeInText 1.4s ease-in-out;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  animation: fadeInText 1.6s ease-in-out;
}

.btn-primary, .btn-secondary {
  padding: 12px 30px;
  background-color: #fffafa;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-primary:hover, .btn-secondary:hover {
  background-color: rgb(0, 0, 0);
  color: #e7b023;
  /* border: 1px solid #333; */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInText {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@media (max-width: 1024px) {
  .home-container {
    padding: 80px 20px;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-content p {
    font-size: 1.1rem;
  }
  
  .btn-primary, .btn-secondary {
    padding: 10px 25px;
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .home-container {
    padding: 60px 20px;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .btn-primary, .btn-secondary {
    padding: 8px 20px;
    font-size: 0.9rem;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .home-container {
    padding: 40px 10px;
  }

  .hero-content h1 {
    font-size: 1.8rem;
  }

  .hero-content p {
    font-size: 0.9rem;
  }

  .btn-primary, .btn-secondary {
    padding: 6px 15px;
    font-size: 0.85rem;
  }
}

.checkout-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #f8f9fa;
  padding: 20px;
  gap: 20px;
  margin: 70px 20px;
}

.checkout-form,
.order-summary {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}

.checkout-form {
  flex: 1;
  min-width: 300px;
}

.order-summary {
  width: 300px;
  min-width: 250px;
  max-height: 400px;
  overflow-y: auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 65px;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #333;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 1rem;
}
.error-message + .input-field {
  margin-top: -4px; /* Adjusts input position when an error is present */
}
.input-field + .error-message {
  margin-top: -8px; /* Adjust spacing between input and error */
  display: block;
}
.address-row {
  display: flex;
  gap: 12px;
}

.checkbox-label,
.billing-option,
.payment-option {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.95rem;
  color: #555;
}

.shipping-method {
  background-color: #f0e4e7;
  padding: 12px;
  margin-bottom: 18px;
  border-radius: 6px;
  font-size: 0.95rem;
  color: #c14b55;
}

.payment-info {
  font-size: 0.95rem;
  color: #555;
  margin-bottom: 10px;
}

.payment-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 18px;
  background-color: #f7f7f7;
  border-radius: 6px;
  margin-bottom: 18px;
}

.payment-description {
  font-size: 0.85rem;
  color: #888;
  text-align: center;
  margin-top: -8px;
}

.order-item {
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #eee;
  padding: 12px 0;
}

.item-image {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  object-fit: cover;
}

.item-details {
  flex: 1;
}

.item-price {
  font-weight: 600;
  color: #333;
}

.summary-details,
.summary-total {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  color: #333;
}

.summary-total {
  font-weight: 700;
  font-size: 1.3rem;
  color: #222;
}
.complete-order-button {
  width: 100%;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
}

.complete-order-button button {
  padding: 14px 26px;
  font-size: 1.1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.complete-order-button button:hover {
  background-color: #0056b3;
}
.summary-container {
  background: #ffffff;
  padding: 10px 60px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
}
.error-message {
  display: flex;
  justify-content: flex-start;
  color: red; /* Red to indicate error */
  font-size: 0.85rem;
  margin: 0 0 8px 0; /* Adjusts for better spacing with input fields */
  font-weight: 500;
}
/* Modal Overlay */
.checkout-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top */
}

/* Modal Content */
.checkout-modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  animation: fadeIn 0.3s ease-out; /* Smooth fade-in effect */
}

/* Heading Style */
.checkout-modal-content h2 {
  font-size: 1.8rem;
  color: #333; /* Green color for success */
  margin-bottom: 1rem;
}

/* Message Paragraph */
.checkout-modal-content p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 1.5rem;
}

/* Close Button */
.checkout-modal-content button {
  background-color: #333;
  color: #fff;
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout-modal-content button:hover {
  background-color: #218838; /* Slightly darker green on hover */
}
.error-text {
  display: flex;
  justify-content: flex-start;
  color: red;
  font-size: 0.875em;
  margin-top: 4px;
}
/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Styling */
@media (max-width: 768px) {
  .error-message {
    font-size: 0.8rem;
  }
  /* Reduce padding and font sizes on tablets */
  .checkout-modal-content {
    padding: 1.5rem;
  }
  .checkout-modal-content h2 {
    font-size: 1.5rem;
  }
  .checkout-modal-content p {
    font-size: 0.9rem;
  }
  .checkout-modal-content button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .error-message {
    font-size: 0.75rem;
  }
  /* Further reduce padding and font sizes on mobile */
  .checkout-modal-content {
    padding: 1rem;
  }
  .checkout-modal-content h2 {
    font-size: 1.4rem;
  }
  .checkout-modal-content p {
    font-size: 0.85rem;
  }
  .checkout-modal-content button {
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
  }
}

@media (max-width: 768px) {
  .summary-container {
    width: 100%;
  }
  .checkout-container {
    flex-direction: column;
  }

  .checkout-form,
  .order-summary {
    width: 100%;
    position: relative;
  }
}

.cart-container {
  width: 90%;
  max-width: 800px;
  margin: auto;
  background-color: #fdfdfd;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.cart-header,
.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #eaeaea;
}

.cart-header span {
  font-size: 14px;
  color: #999;
  font-weight: 500;
}

.product-details {
  display: flex;
  align-items: center;
}

.product-details img {
  width: 70px;
  height: 70px;
  margin-right: 0px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.product-info {
  width: 250px;
}
.product-info h3 {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  color: #333;
}

.product-info p {
  color: #555;
  font-size: 14px;
  margin-top: 5px;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 5px;
}

.quantity-controls button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.quantity-controls button:hover {
  background-color: #e0e0e0;
}

.quantity-controls input {
  width: 40px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 5px;
  background-color: #f9f9f9;
  color: #333;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #f56262;
  font-size: 16px;
  margin-left: 10px;
  transition: color 0.3s;
}

.delete-button:hover {
  color: #e53e3e;
}

.total-price {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.cart-footer {
  text-align: center;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eaeaea;
}

.cart-footer p {
  font-size: 14px;
  color: #777;
  margin-bottom: 10px;
}

.cart-footer a {
  color: #555;
  text-decoration: underline;
  font-weight: 500;
}

.checkout-button {
  margin-top: 10px;
  padding: 12px 24px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s;
}

.checkout-button:hover {
  background-color: #555;
}
.size-selection-container {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 0.5rem;
  font-family: "Arial", sans-serif;
}

.size-label {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.size-dropdown {
  width: 20%;
  /* padding: 0.5rem; */
  font-size: 1rem;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.size-dropdown:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.size-dropdown option {
  font-size: 1rem;
}

@media (max-width: 600px) {
  .cart-header,
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .quantity-controls,
  .total-price {
    margin-top: 10px;
  }

  .cart-footer {
    font-size: 14px;
  }
}

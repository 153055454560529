/* Terms and conditions container */
.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 70px;
  font-family: Arial, sans-serif;
}

.terms-title {
  font-size: 2em;
  color: #333;
  margin-bottom: 0.5em;
}

.terms-section-title {
  font-size: 1.5em;
  color: #444;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.terms-text,
.terms-address {
  font-size: 1em;
  line-height: 1.6;
  color: #555;
}

.terms-address {
  font-style: normal;
  color: #666;
}

/* Responsive styles */
@media (max-width: 768px) {
  .terms-container {
    margin-top: 70px;
    padding: 15px;
  }

  .terms-title {
    font-size: 1.75em;
  }

  .terms-section-title {
    font-size: 1.3em;
  }

  .terms-text,
  .terms-address {
    font-size: 0.95em;
  }
}

@media (max-width: 480px) {
  .terms-container {
    margin-top: 70px;
    padding: 10px;
  }

  .terms-title {
    font-size: 1.5em;
  }

  .terms-section-title {
    font-size: 1.2em;
  }

  .terms-text,
  .terms-address {
    font-size: 0.9em;
  }
}

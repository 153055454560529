.contact-container {
  padding: 70px 20px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

.contact-header h1 {
  font-size: 2.5rem;
}

.contact-header p {
  max-width: 600px;
  margin: 0 auto;
}

.contact-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.contact-form-section {
  max-width: 400px;
  width: 100%;
}

.contact-form-section h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.contact-form-section input,
.contact-form-section textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.contact-form-section input:focus,
.contact-form-section textarea:focus {
  border-color: #333;
}

.contact-form-section button {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.contact-form-section button:hover {
  background-color: #ffcc00;
  color: #333;
}

.contact-map {
  flex: 1 1 300px;
  max-width: 600px;
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  text-align: left;
}

.info-item i {
  font-size: 1.5rem;
  color: #333;
}

@media (max-width: 768px) {
  .contact-header h1 {
    font-size: 2rem;
  }

  .contact-form-section,
  .contact-map {
    max-width: 100%;
  }

  .contact-info {
    align-items: flex-start;
    padding: 0 20px;
  }
}

/* Cancel Policy container */
.cancel-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 70px;
  font-family: Arial, sans-serif;
}

.cancel-policy-title {
  font-size: 2em;
  color: #333;
  margin-bottom: 0.5em;
}

.cancel-policy-section-title {
  font-size: 1.5em;
  color: #444;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.cancel-policy-text,
.cancel-policy-address {
  font-size: 1em;
  line-height: 1.6;
  color: #555;
}

.cancel-policy-address {
  font-style: normal;
  color: #666;
}

/* Responsive styles */
@media (max-width: 768px) {
  .cancel-policy-container {
    margin-top: 70px;
    padding: 15px;
  }

  .cancel-policy-title {
    font-size: 1.75em;
  }

  .cancel-policy-section-title {
    font-size: 1.3em;
  }

  .cancel-policy-text,
  .cancel-policy-address {
    font-size: 0.95em;
  }
}

@media (max-width: 480px) {
  .cancel-policy-container {
    margin-top: 70px;
    padding: 10px;
  }

  .cancel-policy-title {
    font-size: 1.5em;
  }

  .cancel-policy-section-title {
    font-size: 1.2em;
  }

  .cancel-policy-text,
  .cancel-policy-address {
    font-size: 0.9em;
  }
}

.services-section {
  padding: 40px 150px;
  text-align: center;
  background-color: #ffffff;
}

.services-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.services-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 40px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media screen and (max-width: 992px) {
  .services-section {
    padding: 10px 80px;
  }
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .services-section {
    padding: 10px 30px;
  }
  .services-grid {
    grid-template-columns: 1fr;
  }
}

.service-card {
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  text-align: left;
}

.service-card:hover {
  transform: translateY(-10px);
  background-color: #beb7b7;
  color: white;
}

.service-icon {
  font-size: 2.5rem;
  color: #fbbd08;
}

.service-card h3 {
  font-size: 1.25rem;
  margin: 10px 0;
  color: inherit;
}

.service-card p {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.service-card:hover p {
  color: white;
}

.read-more {
  color: #fbbd08;
  text-decoration: none;
  font-weight: bold;
}

.read-more:hover {
  text-decoration: underline;
}

.service-card:hover .read-more {
  color: #fbbd08;
}
